<template>
	<v-card>
		<v-card-title>
			{{ workbook.title }}
			<span v-if="isConfirm">검토</span>
			<v-spacer />
			총 {{ workbook.problemCount }} 문제
		</v-card-title>
		<v-divider />
		<v-card-text v-if="!isConfirm">
			<v-stepper v-model="activeStep" elevation="0">
				<v-stepper-items>
					<v-stepper-content
						class="pa-0"
						v-for="step in stepCount"
						:step="step"
						:key="step"
					>
						<div
							v-for="item in problemCountArray.slice(
								(step - 1) * 10,
								(step - 1) * 10 + 10,
							)"
							:key="item"
							:class="[
								`question-wrapper-${item}`,
								item % 2 === 0 ? 'even' : 'odd',
							]"
						>
							<v-col cols="12" md="12" sm="12">
								<multiple-choice-questions
									v-if="workbook"
									:is-confirm="false"
									:workbook-id="workbook.id"
									:question-number="item"
									@add-answer-sheet="addAnswerSheet"
								/>
							</v-col>
						</div>
						<div class="d-flex justify-center mt-3">
							<v-btn
								v-if="activeStep > 1"
								color="secondary"
								class="mr-1"
								@click="activeStep = activeStep - 1"
							>
								이전
							</v-btn>
							<v-btn
								color="primary"
								class="mr-1"
								@click="moveNext(step)"
								v-if="activeStep < stepCount"
							>
								다음
							</v-btn>
							<v-btn
								color="accent"
								@click="confirm(step)"
								v-if="
									activeStep == stepCount ||
									answerSheet.length === workbook.problemCount
								"
							>
								검토
							</v-btn>
						</div>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-card-text>
		<v-card-text v-else>
			<div v-for="item in problemCountArray" :key="item">
				<v-col cols="12" md="12" sm="12" @click="moveToAnswerSheet(item)">
					<multiple-choice-questions
						:is-confirm="true"
						:workbook-id="workbook.id"
						:question-number="item"
						:answer-sheet="answerSheet"
						@add-answer-sheet="addAnswerSheet"
					/>
				</v-col>
			</div>
			<div class="d-flex justify-center">
				<v-btn color="primary" @click="submit" :disabled="hasTestSubmitted">
					{{ hasTestSubmitted ? '이미 시험지 제출이 완료됐습니다' : '제출' }}
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { ref, nextTick, watch } from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@core/utils'
import { dateFormatUtcToKst, dateDiff } from '@/filter'
import { confirmSwal, successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'

import MultipleChoiceQuestions from './components/MultipleChoiceQuestions.vue'

const PAGE_PER_QUESTION_LIMIT = 10

export default {
	components: {
		MultipleChoiceQuestions,
	},
	setup() {
		const { route, router } = useRouter()
		const isConfirm = ref(false)
		const workbook = ref({})
		const hasTestSubmitted = ref(false)
		const workbookUsersTest = ref({})
		const activeStep = ref(1)
		const stepCount = ref(2)
		const problemCountArray = ref([])
		const workbookId = ref(route.value.params.workbookId)
		const answerSheet = ref([])

		const getWorkBookDetail = async () => {
			workbook.value = await MarketLicenseService.getWorkBookDetail(
				workbookId.value,
			)
			stepCount.value = Math.ceil(
				workbook.value.problemCount / PAGE_PER_QUESTION_LIMIT,
			)
			for (let i = 1; i <= workbook.value.problemCount; i++) {
				problemCountArray.value.push(i)
			}
		}
		getWorkBookDetail()

		const getWorkbookUserTest = async () => {
			workbookUsersTest.value = await MarketLicenseService.getWorkbookUserTest(
				workbookId.value,
			)

			if (workbookUsersTest.value.length > 0) {
				isConfirm.value = true
				hasTestSubmitted.value = true
			}
		}
		getWorkbookUserTest()

		watch(
			() => workbookId.value,
			currentValue => {
				if (currentValue && !hasTestSubmitted) {
					answerSheet.value =
						store.getters['mypage/getAnswerSheet'](currentValue)
				}
			},
			{
				immediate: true,
			},
		)

		watch(
			() => hasTestSubmitted.value,
			currentValue => {
				if (currentValue) {
					store.dispatch('mypage/setAnswerSheet', {
						workbookId: workbookId.value,
						answerSheet: workbookUsersTest.value[0].answerSheet,
					})
				}
			},
			{
				immediate: true,
			},
		)

		const addAnswerSheet = answer => {
			const found = answerSheet.value.some(obj => obj.key === answer.key)
			if (found) {
				const index = answerSheet.value.findIndex(obj => obj.key === answer.key)
				answerSheet.value[index].value = answer.value
			} else {
				answerSheet.value.push(answer)
			}
			store.dispatch('mypage/setAnswerSheet', {
				workbookId: workbook.value.id,
				answerSheet: answerSheet.value,
			})
		}

		const moveNext = step => {
			for (
				let i = (step - 1) * 10 + 1;
				i <= step * PAGE_PER_QUESTION_LIMIT;
				i++
			) {
				const temp = []
				const questions = document.getElementsByName('question' + i)
				for (const element of questions) {
					temp.push(element.checked)
				}

				if (!temp.includes(true)) {
					warningSwal('문제의 답안지를 모두 작성해주세요')
					return
				}
			}
			activeStep.value = activeStep.value + 1
		}

		const moveToAnswerSheet = async questionNumber => {
			if (hasTestSubmitted.value) {
				return
			}

			const confirm = await confirmSwal(
				`${questionNumber}번 문제를 수정하시겠습니까?<br /> '네' 버튼을 누르면 해당 페이지로 이동합니다`,
			)
			if (confirm.isConfirmed) {
				isConfirm.value = false
				activeStep.value = Math.ceil(questionNumber / PAGE_PER_QUESTION_LIMIT)

				nextTick(() => {
					document
						.querySelector('.v-stepper .question-wrapper-' + questionNumber)
						.classList.add('active')
				})
			}
		}

		const confirm = step => {
			for (let i = (step - 1) * 10 + 1; i <= workbook.value.problemCount; i++) {
				const temp = []
				const questions = document.getElementsByName('question' + i)
				for (const element of questions) {
					temp.push(element.checked)
				}

				if (!temp.includes(true)) {
					warningSwal('문제의 답안지를 모두 작성해주세요')
					return
				}
			}
			isConfirm.value = !isConfirm.value
		}

		const hasTestTimecome = () => {
			const testAt = dateFormatUtcToKst(
				workbook.value?.licenseTestRound?.testAt,
			)
			const diff = dateDiff(new Date(), testAt, 'minutes')
			return diff < 0 ? true : false
		}

		const submit = async () => {
			if (!hasTestTimecome()) {
				warningSwal(
					'지금은 시험 제출 가능한 시간이 아닙니다.<br />대시보드 화면으로 돌아갑니다.',
				)
				return
			} else {
				const confirm = await confirmSwal(
					'시험지를 제출하시겠습니까? 제출하시면 더 이상 답안지를 수정할 수 없습니다',
				)
				if (confirm.isConfirmed) {
					try {
						await MarketLicenseService.postWorkbookUserTest(
							workbookId.value,
							answerSheet.value,
						)
						store.dispatch('mypage/deleteAnswerSheet', {
							workbookId: workbookId.value,
						})
						await successSwal({
							html: '답안 제출이 완료 되었습니다.<br />감독관 확인 후 퇴실하세요.',
							confirmButtonText: '감독관 확인',
							allowOutsideClick: false,
						})
						router.go()
					} catch (e) {
						warningSwal(
							e.response.status === 400
								? e.response.data.message
								: '시험지를 제출하데 문제가 발생했습니다.',
						)
					}
				}
			}
		}

		return {
			workbook,
			answerSheet,
			activeStep,
			stepCount,
			problemCountArray,
			isConfirm,
			hasTestSubmitted,

			moveNext,
			addAnswerSheet,
			moveToAnswerSheet,
			confirm,
			submit,
		}
	},
}
</script>
<style lang="scss" scoped>
div[class^='question-wrapper'] {
	min-height: 70px;

	&.active {
		border: 1px solid red;
		border-radius: 5px;
	}

	&.odd {
		background-color: rgb(226, 226, 226, 0.2);
	}
}
</style>
