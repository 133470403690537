<template>
	<div class="d-flex align-center" style="min-height: 55px">
		<div style="width: 75px; letter-spacing: -1px" class="d-flex align-center">
			<h2 class="mr-1">{{ questionNumber }}</h2>
			번 문제
		</div>
		<label
			v-for="index in totalQuestionCount"
			:key="index"
			class="wrapper items-center align-center"
		>
			<input
				type="radio"
				:name="'question' + questionNumber"
				:value="index"
				:checked="isChecked(questionNumber, index)"
				:disabled="isConfirm"
				@click="addAnswerSheet(questionNumber, index)"
			/>
			<span class="checkmark">{{ index }}</span>
		</label>
	</div>
</template>
<script>
import { ref } from '@vue/composition-api'
import store from '@/store'

const TOTAL_QUESTION_COUNT = 4

export default {
	props: {
		isConfirm: {
			type: Boolean,
			default: false,
		},
		questionNumber: {
			type: Number,
			required: true,
		},
		workbookId: {
			type: Number,
			required: true,
		},
		hasTestSubmitted: {
			type: Boolean,
			default: false,
		},
		answerSheet: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, { emit }) {
		const totalQuestionCount = ref(TOTAL_QUESTION_COUNT)

		const addAnswerSheet = (questionNumber, answer) => {
			const data = {
				key: questionNumber - 1,
				value: answer,
			}
			emit('add-answer-sheet', data)
		}

		const isChecked = (questionNumber, _index) => {
			const answerSheet = store.getters['mypage/getAnswerSheet'](
				props.workbookId,
			)
			if (answerSheet.length > 0) {
				const index = answerSheet.findIndex(
					obj => parseInt(obj.key) === questionNumber - 1,
				)
				if (index !== -1) {
					return parseInt(answerSheet[index].value) === _index
				}
			} else {
				return false
			}
		}

		return {
			totalQuestionCount,
			isChecked,

			addAnswerSheet,
		}
	},
}
</script>
<style lang="scss" scoped>
.wrapper {
	display: block;
	position: relative;
	cursor: pointer;
	text-align: center;
	font-size: 22px;
	left: 10px;
	bottom: 18px;
	width: 15%;

	@media screen and (max-width: 768px) {
		width: 20%;
	}

	/* On mouse-over, add a grey background color */
	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* Hide the browser's default radio button */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		/* When the radio button is checked, add a blue background */
		&:checked ~ .checkmark {
			color: white;
			background-color: #333333;
			transition: 0.4s;
		}
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		height: 32px;
		width: 32px;
		border-radius: 50%;
		background-color: #ffffff;
		border: 1px solid #ccc;
	}
}
</style>
