var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.workbook.title)+" "),(_vm.isConfirm)?_c('span',[_vm._v("검토")]):_vm._e(),_c('v-spacer'),_vm._v(" 총 "+_vm._s(_vm.workbook.problemCount)+" 문제 ")],1),_c('v-divider'),(!_vm.isConfirm)?_c('v-card-text',[_c('v-stepper',{attrs:{"elevation":"0"},model:{value:(_vm.activeStep),callback:function ($$v) {_vm.activeStep=$$v},expression:"activeStep"}},[_c('v-stepper-items',_vm._l((_vm.stepCount),function(step){return _c('v-stepper-content',{key:step,staticClass:"pa-0",attrs:{"step":step}},[_vm._l((_vm.problemCountArray.slice(
							(step - 1) * 10,
							(step - 1) * 10 + 10
						)),function(item){return _c('div',{key:item,class:[
							("question-wrapper-" + item),
							item % 2 === 0 ? 'even' : 'odd' ]},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.workbook)?_c('multiple-choice-questions',{attrs:{"is-confirm":false,"workbook-id":_vm.workbook.id,"question-number":item},on:{"add-answer-sheet":_vm.addAnswerSheet}}):_vm._e()],1)],1)}),_c('div',{staticClass:"d-flex justify-center mt-3"},[(_vm.activeStep > 1)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"secondary"},on:{"click":function($event){_vm.activeStep = _vm.activeStep - 1}}},[_vm._v(" 이전 ")]):_vm._e(),(_vm.activeStep < _vm.stepCount)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.moveNext(step)}}},[_vm._v(" 다음 ")]):_vm._e(),(
								_vm.activeStep == _vm.stepCount ||
								_vm.answerSheet.length === _vm.workbook.problemCount
							)?_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.confirm(step)}}},[_vm._v(" 검토 ")]):_vm._e()],1)],2)}),1)],1)],1):_c('v-card-text',[_vm._l((_vm.problemCountArray),function(item){return _c('div',{key:item},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"},on:{"click":function($event){return _vm.moveToAnswerSheet(item)}}},[_c('multiple-choice-questions',{attrs:{"is-confirm":true,"workbook-id":_vm.workbook.id,"question-number":item,"answer-sheet":_vm.answerSheet},on:{"add-answer-sheet":_vm.addAnswerSheet}})],1)],1)}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.hasTestSubmitted},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.hasTestSubmitted ? '이미 시험지 제출이 완료됐습니다' : '제출')+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }